const lockScroll = () => {
  const scrollBarCompensation = window.innerWidth - document.body.offsetWidth
  document.body.style.overflow = 'hidden'

  // handle layout shift from scrollbar
  document.body.style.paddingRight = `${scrollBarCompensation}px`
}

const unlockScroll = () => {
  document.body.style.overflow = ''
  document.body.style.paddingRight = ''
}

const SCROLL_LOCK_FUNCTIONS = {
  lockScroll,
  unlockScroll,
}

export const useScrollLock = () => {
  return SCROLL_LOCK_FUNCTIONS
}
