import { forwardRef } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { WithChildren } from 'types'
import { block } from 'utils/bem'

import styles from './active-link.module.scss'

type LinkProps = WithChildren<{
  href: string
  disabled?: boolean
  onClick?: () => void
  className?: string
}>

//eslint-disable-next-line react/display-name
export const ActiveLink = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href, disabled, onClick, className, children }, ref) => {
    const { asPath } = useRouter()

    const isActive = asPath === href

    const activeClass = isActive ? 'active' : null
    const disabledClass = disabled ? 'disabled' : null

    return (
      <Link href={href} aria-disabled={disabled}>
        <a
          aria-current={isActive}
          aria-disabled={disabled}
          role="link"
          tabIndex={0}
          onClick={onClick}
          className={block(
            styles,
            'link',
            [activeClass, disabledClass],
            className
          )}
          ref={ref}
        >
          {children}
        </a>
      </Link>
    )
  }
)
