import * as React from 'react'
import Link from 'next/link'
import { WithChildren } from 'types/with-children'
import { block } from 'utils/bem'
import styles from './button.module.scss'

type ButtonColor = 'primary' | 'overlay' | 'secondary'
type ButtonStyle = 'filled' | 'outline' | 'text' | 'unstyled' | 'orb'
type ElementTag = 'button' | 'link' | 'anchor'

type ButtonProps = WithChildren<{
  as?: ElementTag
  to?: string
  color?: ButtonColor
  style?: ButtonStyle
  type?: 'button' | 'submit' | 'reset'
  /**
   * Reduces vertical padding and uses small button text
   */
  size?: 'small'
  fitContent?: boolean
  /**
   * Currently icon is implemented in parent component,
   * this just adds spacing between text and icon
   */
  iconPosition?: 'left' | 'right'
  isDisabled?: boolean
  /**
   * Only required if the button has no text (e.g. icon only and orb button)
   */
  ariaLabel?: string
  /**
   * This text will show when the button is hovered over
   */
  title?: string
  /**
   * If it is a link or anchor, whether to open in a new tab or not
   */
  newTab?: boolean
  onClick?: () => void
  className?: string
}>

export const Button = ({
  as = 'button',
  to = '#',
  color = 'primary',
  style = 'filled',
  type = 'button',
  size,
  fitContent,
  iconPosition,
  isDisabled,
  ariaLabel,
  title,
  newTab = false,
  onClick,
  className,
  children,
}: ButtonProps) => {
  const elementType = as
  const classNames = block(
    styles,
    'button',
    [color, style, size, fitContent && 'fitContent', iconPosition],
    className
  )

  if (elementType === 'link')
    return (
      <Link href={to}>
        <a
          className={classNames}
          aria-label={ariaLabel}
          aria-disabled={isDisabled}
          title={title}
          rel={newTab ? 'noopener noreferrer' : undefined}
          target={newTab ? '_blank' : undefined}
        >
          {children}
        </a>
      </Link>
    )
  else if (elementType === 'anchor') {
    return (
      <a
        href={to}
        className={classNames}
        aria-label={ariaLabel}
        aria-disabled={isDisabled}
        title={title}
        rel={newTab ? 'noopener noreferrer' : undefined}
        target={newTab ? '_blank' : undefined}
      >
        {children}
      </a>
    )
  } else
    return (
      <button
        className={classNames}
        aria-label={ariaLabel}
        aria-disabled={isDisabled}
        disabled={isDisabled}
        title={title}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    )
}
