import * as React from 'react'
import { block } from '../../utils/bem'
import type { WithChildren } from 'types/with-children'

import styles from './typography.module.scss'

type TypographyProps = WithChildren<{
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
  className?: string
  variant?:
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'sub-heading-1'
    | 'sub-heading-2'
    | 'button-regular'
    | 'button-regular-underline'
    | 'body-regular'
    | 'body-regular-book'
    | 'body-small'
    | 'body-small-book'
    | 'body-xsmall'
    | 'body-xsmall-book'
    | 'button-small'
    | 'button-small-underline'
    | 'sub-heading-1-mobile'
    | 'sub-heading-2-mobile'
  id?: string
  maxWidth?: boolean
  noMargins?: boolean
  noBottomMargin?: boolean
  noTopMargin?: boolean
  color?:
    | 'sky-dark'
    | 'sky-base'
    | 'teal-light'
    | 'sky-white'
    | 'error'
    | 'ink-darkest'
    | 'ink-lighter'
}>

export const Typography = ({
  component,
  className,
  children,
  variant = 'body-regular',
  id,
  maxWidth,
  noMargins,
  noBottomMargin,
  noTopMargin,
  color,
  ...props
}: TypographyProps) => {
  const Component = component ? component : 'p'

  return (
    <Component
      className={block(
        styles,
        'typography',
        [
          variant,
          noMargins && 'noMargins',
          noBottomMargin && 'noBottomMargin',
          noTopMargin && 'noTopMargin',
          maxWidth && 'max-width',
          color && `${color}`,
        ],
        className
      )}
      {...props}
      id={id}
    >
      {children}
    </Component>
  )
}
